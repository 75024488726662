import {
  ActionDeleteWishlistItem,
  AddToCart,
} from '@actions/checkout/cart/cart';
import Button from '@components/ui/button';
import Image from '@components/ui/image';
import { useCart } from '@contexts/cart/use-cart';
import { useWishlist } from '@contexts/cart/use-wishlist';
import { ProductConfig } from '@services/mage/catalog-product-config';
import {
  ProductOptionValuesType,
  TMetal,
  TProduct,
  TSaleOption,
} from '@services/mage/catalog-product-types';
import { ProductUtils } from '@services/mage/catalog-product-utils';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import React, { useCallback } from 'react';
import { IoIosHeart, IoIosHeartEmpty } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useTranslation } from 'src/app/i18n/client';
import { DefaultImage } from '../default-image';
import { ProductMetalColor } from '../product-details/product-metal-color';
import { ProductPriceCardDisplay } from '../product-details/product-price-card-display';
import { GetStoreId } from '@services/mage/cms-helper';
import { shoppingCartManager } from '@services/events/shopping-cart-listener';

interface ProductProps {
  lang: string;
  productLink: string;
  product: TProduct;
  className?: string;
}

const ProductCardJewel = ({
  product,
  productLink,
  className,
  lang,
}: ProductProps) => {
  const { t } = useTranslation('en', 'common');
  const [hover, setHover] = React.useState(false);
  const storeId = GetStoreId(lang);

  const { customer, cart, currency } = useCart();
  // set empty customer data

  const {
    addItemToWishlist,
    isInWishlist,
    removeItemFromWishlist,
    wishlist,
    updateWishlistId,
  } = useWishlist();
  const wishlistId = wishlist.wishlistId;

  const [currentItem, setCurrentItem] = React.useState(product);

  const hasWishlist = isInWishlist(Number(product.id));
  const [favorite, setFavorite] = React.useState(hasWishlist);

  const thumbnailImage = ProductUtils.getThumbnailImageUrl(currentItem);
  const hoverImage = ProductUtils.getHoverImageUrl(currentItem);

  const [thumbnail, setThumbnail] = React.useState(thumbnailImage);
  const [hoverThumbnail, setHoverThumbnail] = React.useState(hoverImage);
  const [price, setPrice] = React.useState(currentItem.price);
  const [name, setName] = React.useState(currentItem.name);
  const [metalActive, setMetalActive] = React.useState('');

  const [selectedMetal, setSelectedMetal] = React.useState<TMetal>({
    sku: currentItem.sku,
    name: currentItem.name,
    metal: ProductUtils.skuLastPart(currentItem.sku),
    price: currentItem.price,
    images: {
      thumbnail: thumbnailImage,
      hover: hoverImage,
    },
  });

  const metalOptions =
    ProductUtils.getOptionValues(currentItem.options, 'Metal') ?? [];
  const metalRelatedProducts = currentItem.metal_options ?? [];

  const addToWishlist = useCallback(
    (product: TProduct) => {
      const item = ProductUtils.createProductItem(product);
      addItemToWishlist(item, 1);
      setFavorite(true);
      toast.success('Added to wishlist', { autoClose: 1500 });

      shoppingCartManager.addToCart({
        customerId: customer?.entity_id,
        cartlists: cart.items,
        wishlists: [item],
      });
    },
    [addItemToWishlist, setFavorite, ProductUtils],
  );

  // remove product from wishlist
  const removeToWistlist = useCallback(
    (product: TProduct) => {
      removeItemFromWishlist(Number(product.id));
      setFavorite(false);
      toast.success('Removed to wishlist', { autoClose: 1500 });

      if (customer) {
        ActionDeleteWishlistItem(
          JSON.stringify({
            customerId: customer.entity_id,
            itemId: Number(product.id),
          }),
        );
      }
    },
    [removeItemFromWishlist, setFavorite, customer, wishlistId],
  );

  /* const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<ProductOptionsFormType>({
    defaultValues: {} as ProductOptionsFormType,
  }); */

  const [saleOption, setSaleOption] = React.useState<TSaleOption>(null);

  React.useEffect(() => {
    // get promotion info
    ProductUtils.getProductWithPromoPrice(product.sku, storeId).then((res) => {
      setSaleOption(res);
    });
  }, []);

  return (
    <article
      className={cn(
        'flex flex-col group overflow-hidden rounded-md cursor-pointer transition-all duration-300 relative h-full',
        className,
      )}
      title={name}
    >
      <div className="relative shrink-0">
        <div
          className="overflow-hidden mx-auto w-full transition duration-200 ease-in-out transform group-hover:scale-105 relative"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onMouseDown={() => {
            sessionStorage.setItem(
              '__jwl_scrollPos',
              window.scrollY.toString(),
            );
          }}
        >
          {!isEmpty(thumbnail) ? (
            <Link href={productLink.toLowerCase()}>
              {!hover ? (
                <Image
                  src={thumbnail ?? DefaultImage}
                  alt={name || 'Product Image'}
                  quality={ProductConfig.image.thumbnail.quality}
                  priority
                  width={ProductConfig.image.thumbnail.width}
                  height={ProductConfig.image.thumbnail.height}
                  className="object-cover bg-fill-thumbnail"
                />
              ) : (
                <Image
                  src={hoverThumbnail ?? DefaultImage}
                  alt={name || 'Product Image'}
                  quality={ProductConfig.image.thumbnail.quality}
                  priority
                  width={ProductConfig.image.thumbnail.width}
                  height={ProductConfig.image.thumbnail.height}
                  className="object-cover bg-fill-thumbnail"
                />
              )}
            </Link>
          ) : (
            <Link href={productLink.toLowerCase()}>
              <Image
                src={DefaultImage}
                alt={name || 'Product Image'}
                quality={ProductConfig.image.thumbnail.quality}
                priority
                width={ProductConfig.image.thumbnail.width}
                height={ProductConfig.image.thumbnail.height}
                className="object-cover bg-fill-thumbnail"
              />
            </Link>
          )}
        </div>

        <div className="flex gap-1 absolute left-1 sm:left-2 top-2 md:top-3 ">
          {saleOption && saleOption?.discount_amt > 0 && (
            <div className="py-0.5 sm:py-1.5 px-2 sm:px-3 rounded-full bg-red-50 z-10 flex justify-center items-center ring-1 ring-red-600/20 ring-inset">
              <span className="text-red-700 font-normal md:font-semibold font-outfit text-[9px] md:text-xs uppercase">
                -{saleOption?.discount_lbl}
              </span>
            </div>
          )}

          {/* Vermeil Collection Label */}
          {ProductUtils.getCategoryIds(product).includes(184) && (
            <div className="py-0.5 sm:py-1.5 px-2 sm:px-3 rounded-full bg-green-50 z-10 flex justify-center items-center ring-1 ring-green-600/20 ring-inset">
              <span className="text-green-700 font-normal md:font-semibold font-outfit text-[9px] md:text-xs uppercase">
                Online Exclusive
              </span>
            </div>
          )}
        </div>

        <div className="h-6 w-6 absolute right-4 top-4 z-10">
          {favorite ? (
            <Button
              className="bg-transparent"
              aria-label='Remove from wishlist'
              variant="plain"
              onClick={() => removeToWistlist(currentItem)}
            >
              <IoIosHeart className="text-2xl md:text-[26px] ltr:mr-2 rtl:ml-2 transition-all" />
            </Button>
          ) : (
            <Button
              className="bg-transparent"
                aria-label='Add to wishlist'
              variant="plain"
              onClick={() => addToWishlist(currentItem)}
            >
              <IoIosHeartEmpty className="text-2xl md:text-[26px] ltr:mr-2 rtl:ml-2 transition-all group-hover:text-brand" />
            </Button>
          )}
        </div>
      </div>

      <div className="flex flex-col px-3 md:px-4 lg:px-0 pb-5 lg:pb-6 lg:pt-1.5 h-full">
        <div className="mb-1 mt-3">
          <div className="flex items-center">
            {!isEmpty(metalOptions) &&
              metalOptions.map((value: ProductOptionValuesType, idx) => (
                <div key={idx} className="flex flex-wrap flex-row">
                  <ProductMetalColor
                    metal={value.title}
                    metalActive={metalActive}
                    onMouseEnter={() => {
                      const metal = ProductUtils.getMetalRelatedProducts(
                        metalRelatedProducts,
                        value.sku,
                      );

                      if (
                        metal &&
                        !isEmpty(metal?.images.thumbnail) &&
                        !isEmpty(metal?.images.hover)
                      ) {
                        const thumbnail =
                          ProductUtils.getMetalRelatedThumbnail(metal);
                        setName(metal.name);
                        setPrice(metal.price);
                        setThumbnail(thumbnail.thumbnail);
                      }
                    }}
                    onMouseLeave={() => {
                      const thumbnail =
                        ProductUtils.getMetalRelatedThumbnail(selectedMetal);

                      if (
                        !isEmpty(thumbnail.thumbnail) &&
                        !isEmpty(thumbnail.hover)
                      ) {
                        setThumbnail(thumbnail.thumbnail);
                        setHoverThumbnail(thumbnail.hover);
                        setName(selectedMetal.name);
                        setPrice(selectedMetal.price);
                      }
                    }}
                    onClick={() => {
                      const metal = ProductUtils.getMetalRelatedProducts(
                        metalRelatedProducts,
                        value.sku,
                      );

                      if (
                        metal &&
                        !isEmpty(metal?.images.thumbnail) &&
                        !isEmpty(metal?.images.hover)
                      ) {
                        const thumbnail =
                          ProductUtils.getMetalRelatedThumbnail(metal);

                        setName(metal.name);
                        setPrice(metal.price);
                        setThumbnail(thumbnail.thumbnail);
                        setHoverThumbnail(thumbnail.hover);
                        setMetalActive(
                          metal.metal
                            ?.toUpperCase()
                            .replace('PT900', 'PT')
                            .replace('PT950', 'PT'),
                        );
                        setSelectedMetal(metal);
                      }
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
        <h3 className="text-brand-dark text-13px sm:text-sm lg:text-15px leading-6 sm:leading-6 mb-1.5 mt-3 break-words min-h-10">
          <Link href={productLink} className="break-normal">
            {name}
          </Link>
        </h3>
        <div className="md:mb-2.5 block my-2">
          {
            <ProductPriceCardDisplay
              lang={lang}
              sku={product.sku}
              price={price}
              saleOption={saleOption}
            />
          }
        </div>
      </div>
    </article>
  );
};

export default ProductCardJewel;
