import { metalColorOptions } from '@services/mage/catalog-product-config';
import React, { MouseEventHandler, useCallback } from 'react';
import Image from 'next/image';
import clsx from 'clsx';

type TProps = {
  metal: string;
  metalActive: string;
  onMouseEnter: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave: MouseEventHandler<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const ProductMetalColor = ({
  metal,
  metalActive,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: TProps) => {
  const hasMetalColor = useCallback(
    (color: string) => {
      const matchedOption = metalColorOptions.find((opt) => color === opt.name);
      return matchedOption;
    },
    [metalColorOptions],
  );

  const ifHasMetalColor = hasMetalColor(metal);

  return (
    <>
      {ifHasMetalColor && (
        <div className="flex mr-2">
          <button
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            className={clsx(
              `${metalActive.toUpperCase() !== ifHasMetalColor.value.toUpperCase() && `border-transparent`} border cursor-pointer rounded-full hover:border-gray-400`,
              metalActive.toUpperCase() ===
                ifHasMetalColor.value.toUpperCase() && 'border border-gray-400',
              'border',
            )}
          >            
            <Image                
              src={ifHasMetalColor.icon}
              alt={metal}
              width={30}
              height={30}
              loading='eager'
            />
          </button>
        </div>
      )}
    </>
  );
};
