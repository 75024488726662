import { useStore } from '@contexts/store/store';
import { useShallow } from 'zustand/react/shallow';

export function useCart() {
  return useStore(
    useShallow((store) => ({
      storeEvent: store.storeEvent,
      storeId: store.storeId,
      currency: store.currency,
      customer: store.customer,
      cart: store.cart,
      checkout: store.checkout,
      setStoreId: store.setStoreId,
      updateItem: store.updateItem,
      addItemToCart: store.addItemToCart,
      removeItemFromCart: store.removeItemFromCart,
      removeItemPairFromCart: store.removeItemPairFromCart,
      clearItemFromCart: store.clearItemFromCart,
      getItemFromCart: store.getItemFromCart,
      isInCart: store.isInCart,
      isInCartStone: store.isInCartStone,
      isInStock: store.isInStock,
      applyDiscount: store.applyDiscount,
      updateItemDiscount: store.updateItemDiscount,
      clearDiscount: store.clearDiscount,
      setDeliveryMethod: store.setDeliveryMethod,
      addAddress: store.addAddress,
      deleteAddress: store.deleteAddress,
      updateAddress: store.updateAddress,
      updateShippingAddress: store.updateShippingAddress,
      updateBillingAddress: store.updateBillingAddress,
      setDefaultBillingAddress: store.setDefaultBillingAddress,
      setDefaultShippingAddress: store.setDefaultShippingAddress,
      updateShopAddress: store.updateShopAddress,
      updatePayment: store.updatePayment,
      setPaymentMethod: store.setPaymentMethod,
      updatePaymentMode: store.updatePaymentMode,
      updateTax: store.updateTax,
      resetCart: store.resetCart,
      setActiveAddress: store.setActiveAddress,
      setCustomer: store.setCustomer,
      addCustomerAddress: store.addCustomerAddress,
      updateQuoteId: store.updateQuoteId,
      updateRuleIds: store.updateRuleIds,
      updateDiscountAction: store.updateDiscountAction,
      updateStoreEvent: store.updateStoreEvent,
      updateSalesPerson: store.updateSalesPerson,
      calculateCart: store.calculateCart,
      updateItemSaleOption: store.updateItemSaleOption,
      resetItemDiscount: store.resetItemDiscount,
    })),
  );
}
