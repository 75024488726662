import { ShoppingCartManager } from "@services/events/shopping-cart";
import http from "@framework/utils/http";
import { klaviyoManager } from "./klaviyo-listener";
import { GetStoreCurrency } from "@services/config/client-helper";
import { GoogleEvents } from "@services/google/google-events";
import { TCart } from "@services/checkout/types";

export const shoppingCartManager = new ShoppingCartManager();

shoppingCartManager.on("addtocart", async(data: any) => {   

    await http.post('/actions/upsert-cart', data);

});

shoppingCartManager.on("addtocart", async(data: any) => { 
    
    const store = sessionStorage.getItem('__jwl_auto_switch_currency') || 'sg';    
    const items = data.postMessage?.cartlists;
    const wishlistsItems = data.postMessage?.wishlists;
    const currency = GetStoreCurrency(store);    
    

    if (items.length > 0) {
        
        // klaviyo add to cart
        klaviyoManager.addToCart(store, items);

        // google view item    
        GoogleEvents.addToCart(currency.value, items);

    }

    // google add to wishlist
    if (wishlistsItems.length > 0) {
        GoogleEvents.addToWishlist(currency.value, wishlistsItems);
    }

});

shoppingCartManager.on("addToWishlist", async(data: TCart) => {   

    GoogleEvents.addToWishlist(data.currency, data.wishlists);

});