import { useStore } from '@contexts/store/store';
import { useShallow } from 'zustand/react/shallow';

export function useWishlist() {
  return useStore(
    useShallow((store) => ({
      wishlist: store.wishlist,
      addItemToWishlist: store.addItemToWishlist,
      removeItemFromWishlist: store.removeItemFromWishlist,
      removeItemFromWishlistStone: store.removeItemFromWishlistStone,
      clearItemFromWishlist: store.clearItemFromWishlist,
      isInWishlist: store.isInWishlist,
      isInWishlistStone: store.isInWishlistStone,
      isWishlistEmpty: store.isWishlistEmpty,
      resetWishlist: store.resetWishlist,
      updateWishlistId: store.updateWishlistId,
    })),
  );
}
