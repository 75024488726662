import { TCart } from "@services/checkout/types";
import EventEmitter from "events";

export class ShoppingCartManager extends EventEmitter {

    constructor() {
        super();
    }

    addToCart(data: TCart) {
        this.emit("addtocart", { postMessage: data});
    }

    onAddToCart(callback: any) {
        this.on("checkout", callback);
    }

    addToWishlist(data: TCart) {
        this.emit("addToWishlist", { postMessage: data});
    }

}