import { useCurrency } from '@contexts/currency/use-currency';
import { useMemo } from 'react';

type PriceType = {
  amount: number;
  baseAmount?: number;
  currencyCode: string;
  currencyRate?: number;
  locale?: string;
  localePrice?: string;
};

type TNumberFormatOptions = {
  //style: 'currency',
  currency: string;
  //currencyDisplay: 'narrowSymbol',
  roundingMode: string;
  roundingPriority: string;
  minimumFractionDigits: number;
  maximumFractionDigits: number;
};

export function formatPrice({
  amount,
  currencyCode,
  currencyRate,
  locale,
}: PriceType) {
  const formatCurrency = new Intl.NumberFormat(locale, {
    //style: 'currency',
    currency: currencyCode,
    /* trailingZeroDisplay: 'auto', */
    maximumFractionDigits: 0,
  });

  const currentRate = currencyRate ?? 1;
  const convertedAmount = currentRate * amount;

  return formatCurrency.format(convertedAmount);
}

export function formatVariantPrice({
  amount,
  baseAmount,
  currencyCode,
  currencyRate,
  locale,
}: PriceType & { baseAmount: number }) {
  const hasDiscount = baseAmount > amount;
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' });
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null;

  const price = formatPrice({ amount, currencyCode, currencyRate, locale });
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currencyCode, currencyRate, locale })
    : null;

  const localePrice = currencyCode + ' ' + price.toString();

  return { price, basePrice, discount, localePrice };
}

export default function usePrice(data?: PriceType | null) {
  const { amount, baseAmount, currencyCode, currencyRate } = data ?? {};
  const locale = 'en';
  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) return '';

    const result = formatPrice({ amount, currencyCode, currencyRate, locale });

    return baseAmount
      ? formatVariantPrice({
          amount,
          baseAmount,
          currencyCode,
          currencyRate,
          locale,
        })
      : result;
  }, [amount, baseAmount, currencyCode, currencyRate]);

  return typeof value === 'string'
    ? {
        price: value,
        basePrice: null,
        discount: null,
        localePrice: currencyCode + ' ' + value.toString(),
      }
    : value;
}

export const getFormattedPrice = ({
  baseAmount,
  amount,
  currencyCode,
  currencyRate,
  locale,
}: PriceType): string => {
  return formatVariantPrice({
    amount: amount,
    baseAmount: baseAmount!,
    currencyCode: currencyCode,
    currencyRate: currencyRate,
    locale: locale,
  }).localePrice;
};

export const getPlainPrice = ({
  baseAmount,
  amount,
  currencyCode,
  currencyRate,
  locale,
}: PriceType): string => {
  return formatPlainPrice({
    amount: amount,
    baseAmount: baseAmount!,
    currencyCode: currencyCode,
    currencyRate: currencyRate,
    locale: locale,
  }).localePrice;
};

export function formatPlainPrice({
  amount,
  baseAmount,
  currencyCode,
  currencyRate,
  locale,
}: PriceType & { baseAmount: number }) {
  const hasDiscount = baseAmount > amount;
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' });
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null;

  const price = formatPrice({ amount, currencyCode, currencyRate, locale });
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currencyCode, currencyRate, locale })
    : null;

  const localePrice = price.toString();

  return { price, basePrice, discount, localePrice };
}

/* export const localePrice = (price: number, lang: string) => {

    const { currency } = useCurrency();

    return formatVariantPrice({ 
        amount: price, 
        baseAmount: price, 
        currencyCode: currency?.symbol!, 
        currencyRate: currency?.rate!, 
        locale: lang 
    }).localePrice;
    
} */
