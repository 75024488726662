import http from "@framework/utils/http";
import { PRODUCT_MAGE_URL } from "@services/mage/catalog-product-config";
import { ProductMediaGalleryEntries } from "@services/mage/catalog-product-types";
import { ProductUtils } from "@services/mage/catalog-product-utils";
import { TCheckoutRequestOrder } from "@services/mage/checkout-types";
import { format } from "date-fns";
import { v1 as uuidv1 } from 'uuid';

export class KlaviyoHelper {

    /**
     * Place order payload
     * https://developers.klaviyo.com/en/docs/guide_to_integrating_a_subscription_ecommerce_platform#ordered-product
     * @param metricName 
     * @param orderType 
     * @param orderData 
     */
    static async placeOrderPayload(metricName: string, orderType: string, orderData: TCheckoutRequestOrder) {    

        const items = orderData.cart.items
        const customer = orderData.billing_address;
        let payload = {
            type: "event",
            attributes: {
                properties: {
                    OrderId: orderData.referenceId,
                    OrderType: orderType,
                    Items: items.map((item) => {
                        return {
                            ProductID: item.productId,
                            SKU: item.sku,
                            ProductName: item.name,
                            Quantity: item.quantity,
                            ItemPrice: item.price,
                            RowTotal: item.price,
                            ProductURL: `${window.location.host}/${item.productUrl}`,
                            ImageURL: item.images.length > 0 ? PRODUCT_MAGE_URL + (item.images[0] as ProductMediaGalleryEntries)?.file : "",
                            ProductCategories: ProductUtils.getCategoryNames(item.categoryIds)
                        }
                    }),
                    Categories: items.reduce((categories, item) => categories.concat(ProductUtils.getCategoryNames(item.categoryIds)), []),
                    Brand: 'Michaeltrio'
                },
                time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),//2022-11-08T00:00:00,
                value: items.reduce((total, item) => total + item.price, 0),
                metric: {
                    data: {
                        type: "metric",
                        attributes: {
                            name: metricName,
                        }
                    }
                },
                profile: {
                    data: {
                        type: "profile",
                        attributes: {
                            email: orderData.email,
                            first_name: customer.firstname,
                            last_name: customer.lastname,
                            organization: "",
                            title: "",
                            image: "",
                            location: {
                                address1: customer?.street,
                                address2: "",
                                city: customer?.city,
                                country: customer?.country_id,
                                region: customer?.region,
                                zip: customer?.postcode,
                                timezone: ""
                            }
                        }
                    }
                }
            }
        }

        if (orderType === 'Ordered Product') {
            // remove the order type
            delete payload.attributes.properties.OrderType
        }

        if (metricName === 'Canceled Subscription') {
            // remove the order type
            delete payload.attributes.properties.OrderType
            delete payload.attributes.properties.Brand

            // add the reason
            payload = {
                ...payload,
                attributes: {
                    ...payload.attributes,
                    properties: {
                        ...payload.attributes.properties,
                        ...{
                            OrderIntervalUnit: 'days',
                            OrderIntervalFrequency: 30,
                            CancellationReason: 'No longer interested'
                        }
                    }
                }
            }

        }
    
        await http.post(`/actions/klaviyo/create-event?store=${orderData.storeId}`, payload)

    }

    static async canceledOrderPayload(orderData: TCheckoutRequestOrder) {
        await this.placeOrderPayload('Canceled Order', 'Canceled Order', orderData)
    }

    static async refundedOrderPayload(orderData: TCheckoutRequestOrder) {
        await this.fullFilledOrderPayload('Refunded Order', orderData)
    }

    /**
     * Fullfilled order payload
     * https://developers.klaviyo.com/en/docs/guide_to_integrating_a_platform_without_a_pre_built_klaviyo_integration#fulfilled-order-canceled-order-and-refunded-order
     * @param orderData 
     */
    static async fullFilledOrderPayload(metricName:string, orderData: TCheckoutRequestOrder) {

        let phone = orderData.billing_address.telephone;

        // Very important to add the dial code and correct the phone number
        if (phone.startsWith('+') === false) {
            //const dialCode = await countryDialCode(orderData.billing_address.country_id)
            // make it safe to avoid error
            // shop phone number
            phone = `+6562990110`
        }
        
        let payload = {
            type: "event",
            attributes: {
                properties: {
                    OrderId: orderData.referenceId,
                    Categories: orderData.cart.items.reduce((categories, item) => categories.concat(ProductUtils.getCategoryNames(item.categoryIds)), []),
                    ItemNames: orderData.cart.items.map((item) => item.name),
                    Brands: orderData.cart.items.map(() => 'Michaeltrio'),
                    DiscountCode: orderData.totalSummary.discountCode,
                    DiscountValue: orderData.totalSummary.discountAmount.toString(),
                    Items: orderData.cart.items.map((item) => {
                        return {
                            ProductID: item.productId,
                            SKU: item.sku,
                            ProductName: item.name,
                            Quantity: item.quantity,
                            ItemPrice: item.price,
                            RowTotal: item.price,
                            ProductURL: `${window.location.host}/${item.productUrl}`,
                            ImageURL: item.images.length > 0 ? PRODUCT_MAGE_URL + (item.images[0] as ProductMediaGalleryEntries)?.file : "",
                            Categories: ProductUtils.getCategoryNames(item.categoryIds),
                            Brand: 'Michael Trio'
                        }
                    }), 
                
                    BillingAddress: {
                        FirstName: orderData.billing_address.firstname,
                        LastName: orderData.billing_address.lastname,                    
                        Address1: orderData.billing_address.street,                    
                        City: orderData.billing_address.city,                    
                        RegionCode: orderData.billing_address.region_id,                    
                        CountryCode: orderData.billing_address.country_id,
                        Zip: orderData.billing_address.postcode,
                        Phone: phone,
                    },
                    ShippingAddress: {                    
                        Address1: orderData.shipping_address.street,                    
                    },
                },
                time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),//2022-11-08T00:00:00,
                value: orderData.cart.items.reduce((total, item) => total + item.price, 0),
                value_currency: orderData.currency_id,
                unique_id: uuidv1(),
                metric: {
                    data: {
                        type: "metric",
                        attributes: {
                            name: metricName,
                        }
                    }
                },
                profile: {
                    data: {
                        type: "profile",
                        attributes: {
                            email: orderData.email,
                            phone_number: phone,
                        }
                    }
                }

            }
            
        }        

        if (metricName === 'Refunded Order') {
            // add reason
            payload = {
                ...payload,
                attributes: {
                    ...payload.attributes,
                    properties: {
                        ...payload.attributes.properties,
                        ...{
                            Reason: 'No longer needed'
                        }
                    }
                }
            
            }
        }

        await http.post(`/actions/klaviyo/create-event?store=${orderData.storeId}`, payload)
              
    }


}