'use client';
import Carousel from '@components/ui/carousel/carousel';
import { SwiperSlide } from '@components/ui/carousel/slider';
import Heading from '@components/ui/heading';
import http from '@framework/utils/http';
import { useQuery } from '@tanstack/react-query';
import Alert from '@components/ui/alert';
import { ProductType, TProduct } from '@services/mage/catalog-product-types';
import ProductCardLoader from '@components/ui/loaders/product-card-loader';
import ProductCardJewel from '../product-cards/product-card-jewel';
import { useCallback } from 'react';
import { useFilterStore } from '@contexts/catalog/filter-context';
import {
  mainCategories,
  productUrlPath,
} from '@services/mage/catalog-product-config';
import { ProductUtils } from '@services/mage/catalog-product-utils';
import React from 'react';

const breakpoints = {
  '1024': {
    slidesPerView: 4.5,
    spaceBetween: 12,
  },
  '768': {
    slidesPerView: 4,
    spaceBetween: 12,
  },
  '680': {
    slidesPerView: 4,
    spaceBetween: 12,
  },
  '375': {
    slidesPerView: 1.5,
    spaceBetween: 4,
  },
  '0': {
    slidesPerView: 1.5,
  },
};

interface TrendCarouselProps {
  lang: string;
  className?: string;
  heading: string;
  paragraph?: string;
  carouselBreakpoint?: {} | any;
  sku: string[];
  uniquekey?: string;
}

export const TrendCarousel = ({
  lang,
  className,
  heading,
  paragraph,
  carouselBreakpoint,
  sku,
  uniquekey = '',
}: TrendCarouselProps) => {
  const basePage = 'jewellery';

  const { filter } = useFilterStore();

  const getTrendJewel = async (sku: string) => {
    const response = await http.get(`/products?sku=${sku}`);
    return response.data;
  };

  const {
    error,
    data,
    isLoading: loading,
  } = useQuery({
    queryKey: ['TrendingJewel', sku],
    queryFn: async () => {
      const responses = await Promise.all(sku.map(getTrendJewel));
      return responses.map((response) => response.result);
    },
  });

  const getProductUrl = useCallback(
    (product: TProduct) => {
      const subCategory = filter.category ?? '';
      const categoryId = productUrlPath[basePage][subCategory] ?? '';

      const url = ProductUtils.getProductUrl(
        product,
        basePage,
        categoryId,
        lang,
      );

      return url;
    },
    [filter, basePage, mainCategories],
  );

  return (
    <div
      className={`max-w-[1440px] px-4 py-6 md:px-6 lg:px-0 relative mx-auto bg-white my-8 sm:my-8  ${className}`}
    >
      <div className="flex flex-wrap items-center justify-center mb-3 md:mb-6">
        <div className="-mt-1.5">
          <Heading variant="jewelCarouselHeader" className="mb-0 text-center">
            {heading}
          </Heading>
        </div>
      </div>
      {paragraph && (
        <div className="flex items-center justify-center mt-2 lg:-mt-2 mb-3 md:mb-6">
          <h3 className="font-outfit text-15px lg:text-[17px] leading-5 lg:leading-7 text-center">
            {paragraph}
          </h3>
        </div>
      )}
      <div className="mx-auto w-full">
        <Carousel
          breakpoints={carouselBreakpoint || breakpoints}
          lang={lang}
          prevActivateId={`trending-carousel-${uniquekey}-button-prev`}
          nextActivateId={`trending-carousel-${uniquekey}-button-next`}
        >
          {error ? (
            <div className="col-span-full">
              <Alert message={error.message} />
            </div>
          ) : (
            <>
              {loading
                ? Array.from({ length: 5 }).map((_, idx) => (
                    <SwiperSlide key={`loading-key-${idx}`}>
                      <ProductCardLoader
                        key={`product-loader-key-${idx}`}
                        uniqueKey={`product-loader-key-${idx}`}
                      />
                    </SwiperSlide>
                  ))
                : data &&
                  data.map((product: ProductType, idx) => (
                    <SwiperSlide key={`jewelsetting-key-${idx}`}>
                      <div className="relative">
                        <div className="overflow-hidden mx-auto w-full relative">
                          <ProductCardJewel
                            key={`product--key-${product.id}`}
                            product={product}
                            productLink={getProductUrl(product)}
                            lang={lang}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
            </>
          )}
        </Carousel>
      </div>
    </div>
  );
};
