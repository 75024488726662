import { IItem, IStoreCart, IStoreCheckout } from "@contexts/store/store-types";
import { KlaviyoManager } from "./klaviyo";
import { TAddToCartItem } from "@services/klaviyo/klaviyo-types";
import { ProductUtils } from "@services/mage/catalog-product-utils";
import { ProductMediaGalleryEntries } from "@services/mage/catalog-product-types";
import { PRODUCT_MAGE_URL } from "@services/mage/catalog-product-config";
import { v1 as uuidv1 } from 'uuid';

import { TCheckoutRequestOrder } from "@services/mage/checkout-types";

import { KlaviyoHelper } from "@services/klaviyo/klaviyo-helper";

export const klaviyoManager = new KlaviyoManager();

klaviyoManager.on("viewedItem", async(item: IItem) => {    
    // check if klaviyo is enabled
    if (Object.hasOwn(window, "klaviyo")) {

        const categoryNames = ProductUtils.getCategoryNames(item.categoryIds);
        const imageUrl = item.images.length > 0 ? PRODUCT_MAGE_URL + (item.images[0] as ProductMediaGalleryEntries)?.file : "";
        const productUrl = `${window.location.host}${item.productUrl}`            

        const itemData = {
            "ProductName": item.name,
            "ProductID": item.id,
            "SKU": item.sku,
            "Categories": categoryNames,
            "ImageURL": imageUrl,
            "URL": productUrl,
            "Brand": "Michael Trio",
            "Price": item.price,
            "CompareAtPrice": item.price
          };

        window.klaviyo.track("Viewed Product", itemData);
    }
});

klaviyoManager.on("addToCart", async(lang: string, items: IItem[]) => {

    // check if klaviyo is enabled
    if (Object.hasOwn(window, "klaviyo")) {        

        items.forEach((item) => {

            const imageUrl = item.images.length > 0 ? PRODUCT_MAGE_URL + (item.images[0] as ProductMediaGalleryEntries)?.file : "";
            const checkoutUrl = `${window.location.host}/${lang.toLowerCase()}/checkout`
            const productUrl = `${window.location.host}${item.productUrl}`
            const categoryNames = ProductUtils.getCategoryNames(item.categoryIds);

            window.klaviyo.track("Added to Cart", {
                "$value": item.price,
                "AddedItemProductName": item.name,
                "AddedItemProductID": item.productId,
                "AddedItemSKU": item.sku,
                "AddedItemCategories": categoryNames,
                "AddedItemImageURL": imageUrl,
                "AddedItemURL": productUrl,
                "AddedItemPrice": item.price,
                "AddedItemQuantity": item.quantity,
                "ItemNames": [item.name],
                "CheckoutURL": checkoutUrl,
                "Items": [{
                    "ProductID": item.productId,
                    "SKU": item.sku,
                    "ProductName": item.name,
                    "Quantity": item.quantity,
                    "ItemPrice": item.price,
                    "RowTotal": item.price,
                    "ProductURL": productUrl,
                    "ImageURL": imageUrl,
                    "ProductCategories": categoryNames
                }
                ]
            });
        })

    }


});


klaviyoManager.on("startedCheckout", async(lang: string, items: IItem[]) => {

    const referenceId = uuidv1();    

    // check if klaviyo is enabled
    if (Object.hasOwn(window, "klaviyo")) {        

        const checkoutUrl = `${window.location.host}/${lang.toLowerCase()}/checkout`        

        let itemsData = [] as TAddToCartItem[];
        let itemNames = [] as string[];
        let categoryNames = [] as string[];        
        let rowTotal = 0;
        
        items.forEach((item) => {

            const imageUrl = item.images.length > 0 ? PRODUCT_MAGE_URL + (item.images[0] as ProductMediaGalleryEntries)?.file : "";            
            const productUrl = `${window.location.host}${item.productUrl}`
            const itemCategoryNames = ProductUtils.getCategoryNames(item.categoryIds);

            itemNames.push(item.name);
            categoryNames = categoryNames.concat(itemCategoryNames);            

            rowTotal += item.price;

            itemsData.push({
                "ProductID": item.productId.toString(),
                "SKU": item.sku,
                "ProductName": item.name,
                "Quantity": item.quantity,
                "ItemPrice": item.price,
                "RowTotal": item.price,
                "ProductURL": productUrl,
                "ImageURL": imageUrl,
                "ProductCategories": itemCategoryNames
            })

        });

        window.klaviyo.track("Started Checkout", {
            "$event_id": `${referenceId}_${new Date().getTime()}`,
            "$value": rowTotal,
            "ItemNames": itemNames,
            "CheckoutURL": checkoutUrl,
            "Categories": categoryNames,
            "Items": itemsData
        });
    }
});

// listen for the order placed event
klaviyoManager.on("placedOrder", async(orderData: TCheckoutRequestOrder) => { 
    
    KlaviyoHelper.placeOrderPayload('Placed Order', 'One time Purchase', orderData);
    
})

klaviyoManager.on("orderedProduct", async(orderData: TCheckoutRequestOrder) => { 
    
    KlaviyoHelper.placeOrderPayload('Ordered Product', 'Ordered Product', orderData);
    
})

klaviyoManager.on("fullFilledOrder", async(orderData: TCheckoutRequestOrder) => {
    
    KlaviyoHelper.fullFilledOrderPayload('Fulfilled Order', orderData);
        
})

klaviyoManager.on("canceledOrder", async(orderData: TCheckoutRequestOrder) => {
    
    KlaviyoHelper.canceledOrderPayload(orderData);
    
})

klaviyoManager.on("refundedOrder", async(orderData: TCheckoutRequestOrder) => {
    
    KlaviyoHelper.refundedOrderPayload(orderData);
    
})