import { IItem } from "@contexts/store/store-types";
import { TCheckoutRequestOrder } from "@services/mage/checkout-types";
import EventEmitter from "events";

export class KlaviyoManager extends EventEmitter {

    constructor() {
        super();
    }

    viewedItem(item: IItem) {
        this.emit("viewedItem", item);
    }

    addToCart(lang: string, items: IItem[]) {
        this.emit("addToCart", lang, items);
    }

    startedCheckout(lang: string, items: IItem[]) {
        this.emit("startedCheckout", lang, items);
    }

    placedOrder(data: TCheckoutRequestOrder) {
        this.emit("placedOrder", data);
    }

    orderedProduct(data: TCheckoutRequestOrder) {
        this.emit("orderedProduct", data);
    }

    fullFilledOrder(data: TCheckoutRequestOrder) {
        this.emit("fullFilledOrder", data);
    }

    canceledOrder(data: TCheckoutRequestOrder) {
        this.emit("canceledOrder", data);
    }

    refundedOrder(data: TCheckoutRequestOrder) {
        this.emit("refundedOrder", data);
    }


}