import { createContext, useContext } from 'react';
import { useStore as useZustandStore } from 'zustand';
import { IStateStore } from './store-create';
import { initializeStore } from './store-create';

export type StoreType = ReturnType<typeof initializeStore>;

const storeContext = createContext<StoreType | null>(null);

export const Provider = storeContext.Provider;

export function useStore<T>(selector: (state: IStateStore) => T) {
  const store = useContext(storeContext);

  if (!store) throw new Error('Store is missing the provider');

  return useZustandStore(store, selector);
}
