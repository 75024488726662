'use client';

import 'swiper/css';
import 'swiper/css/navigation';
export {
  FreeMode,
  Navigation,
  Thumbs,
  Pagination,
  Autoplay,
  Grid,
} from 'swiper/modules';
export { Swiper, SwiperSlide } from 'swiper/react';
export type { SwiperOptions } from 'swiper/types';
