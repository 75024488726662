'use client';
import { ProductUtils } from '@services/mage/catalog-product-utils';
import { TSaleOption } from 'src/services/mage/catalog-product-types';
import React from 'react';
import { GetStoreId } from '@services/mage/cms-helper';

type TProps = {
  lang: string;
  sku: string;
  price: number;
  className?: string;
  saleOption?: TSaleOption;
};

export const ProductPriceCardDisplay = ({
  lang,
  sku,
  price,
  className,
  saleOption
}: TProps) => {
  const [vsaleOption, vsetSaleOption] = React.useState<TSaleOption>(saleOption);
  const storeId = GetStoreId(lang);    

  React.useEffect(() => {
    // get promotion info
    if (!saleOption) {
        ProductUtils.getProductWithPromoPrice(sku, storeId).then((res) => {
            vsetSaleOption(res);
        });
    }

  }, []);  

  return (
    <div className="flex items-center product-price-wrapper">
      {vsaleOption && Number(vsaleOption?.discount_amt) > 0 ? (
        <>
          <span className="discounted-price">
            {ProductUtils.formatPrice(vsaleOption?.discount_amt, lang)}
          </span>
          <span
            className={`final-price line-through ml-2 ${className ? className : 'text-brand-grey-1'}`}
          >
            {ProductUtils.formatPrice(price, lang)}
          </span>
          {/* <span className='text-gray-700 ml-2 font-medium'>-{saleOption.discount_lbl}</span> */}
        </>
      ) : (
        <span className="final-price">
          {ProductUtils.formatPrice(price, lang)}
        </span>
      )}
    </div>
  );
};
