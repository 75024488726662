'use client';
import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type TPathname = {
  pathname: string;
  queryKey: string;
};

export interface IFilterState {
  inputPrice: number[];
  filter: {
    currencyRate?: number;
    category: number;
    styles: number[];
    subStyles?: string[];
    metal: string[];
    collection?: string[];
    origin?: string[];
    price: { min: number; max: number };
    indexPrice?: { min: number; max: number };
    page: number;
    sort: string;
    total: number;
    queryKey?: string;
  };
  pathnames: TPathname[];
  addPathname: (pathname: string, queryKey: string) => void;
  hasPathname: (pathname: string) => TPathname | boolean;
  setInputPrice: (price: number[]) => void;
  setQueryKey: (key: string) => void;
  selectSetting: (setting: number) => void;
  removeSetting: (setting: number) => void;
  updateMetal: (metal: string) => void;
  addMetal: (metal: string) => void;
  removeMetal: (metal: string) => void;
  addCollection: (collection: string) => void;
  addSubStyle: (subStyle: string) => void;
  updatePrice: (price: { min: number; max: number }) => void;
  indexPrice: (price: { min: number; max: number }) => void;
  updateSort: (sort: string) => void;
  updatePage: (page: number) => void;
  updateTotal: (total: number) => void;
  getResultCount: () => number;
  resetFilter: (style?: number) => void;
  setCategory: (category: number) => void;
  setInitialStyle: (style: number) => void;
  updateStyle: (style: number) => void;
  setOrigin: (origin: string) => void;
  removeOrigin: (origin: string) => void;
}

const FilterStateInitial: IFilterState = {
  inputPrice: [0, 0],
  filter: {
    category: 0,
    styles: [],
    subStyles: [],
    metal: [],
    collection: [],
    price: { min: 38, max: 44599 },
    indexPrice: { min: 0, max: 0 },
    page: 1,
    sort: 'price-asc',
    total: 0,
    queryKey: new Date().getTime().toString(),
  },
  pathnames: [],
  addPathname: (pathnames: string, queryKey: string) => {},
  hasPathname: (pathname: string) => false,
  setInputPrice: (price) => {},
  setQueryKey: (key: string) => {},
  setInitialStyle: (style) => {},
  selectSetting: (setting: number) => {},
  removeSetting: (setting: number) => {},
  updateMetal: (metal: string) => {},
  addMetal: (metal: string) => {},
  removeMetal: (metal: string) => {},
  addCollection: (collection: string) => {},
  addSubStyle: (subStyle: string) => {},
  updatePrice: (price: { min: number; max: number }) => {},
  indexPrice: (price: { min: number; max: number }) => {},
  updateSort: (sort: string) => {},
  updatePage: (page: number) => {},
  updateTotal: (total: number) => {},
  getResultCount: () => 0,
  resetFilter: (style?: number) => {},
  setCategory: (category: number) => {},
  updateStyle: (style: number) => {},
  setOrigin: (origin: string) => {},
  removeOrigin: (origin: string) => {},
};

export const createFilterStoreSlice: StateCreator<IFilterState> = (
  set,
  get,
) => ({
  ...FilterStateInitial,
  addPathname: (pathname, queryKey) => {
    const pathnames = get().pathnames;

    // check if the pathname is already in the list
    const isPathnameExist =
      pathnames.find((path) => path.pathname === pathname) || false;
    if (!isPathnameExist) {
      set((state) => ({
        pathnames: [...state.pathnames, { pathname, queryKey }],
      }));
    }
  },
  hasPathname: (pathname) => {
    const pathnames = get().pathnames;
    return pathnames.find((path) => path.pathname === pathname) || false;
  },
  setInputPrice: (price) => {
    set((state) => ({ inputPrice: price }));
  },
  setQueryKey: (key) =>
    set((state) => ({ filter: { ...state.filter, queryKey: key } })),
  selectSetting: (style) =>
    set((state) => ({
      filter: {
        ...state.filter,
        styles: state.filter.styles.includes(style)
          ? state.filter.styles.filter((item) => item !== style)
          : [...state.filter.styles, style],
      },
    })),
  removeSetting: (style) =>
    set((state) => ({
      filter: {
        ...state.filter,
        styles: state.filter.styles.filter((item) => item !== style),
      },
    })),
  updateMetal: (metal) =>
    // not include duplicate metal
    set((state) => ({
      filter: {
        ...state.filter,
        metal: state.filter.metal.includes(metal)
          ? state.filter.metal.filter((item) => item !== metal)
          : [...state.filter.metal, metal],
      },
    })),
  addMetal: (metal) =>
    set((state) => ({
      filter: {
        ...state.filter,
        metal: state.filter.metal.includes(metal)
          ? state.filter.metal.filter((item) => item !== metal)
          : [...state.filter.metal, metal],
      },
    })),
  removeMetal: (metal) =>
    set((state) => ({
      filter: {
        ...state.filter,
        metal: state.filter.metal.filter((item) => item !== metal),
      },
    })),
  addCollection: (collection) =>
    set((state) => ({
      filter: {
        ...state.filter,
        collection: state.filter.collection.includes(collection)
          ? state.filter.collection.filter((item) => item !== collection)
          : [...state.filter.collection, collection],
      },
    })),
  addSubStyle: (subStyle) =>
    set((state) => ({
      filter: {
        ...state.filter,
        subStyles: state.filter.subStyles.includes(subStyle)
          ? state.filter.subStyles.filter((item) => item !== subStyle)
          : [...state.filter.subStyles, subStyle],
      },
    })),
  updatePrice: (price) =>
    set((state) => ({ filter: { ...state.filter, price } })),
  indexPrice: (price) =>
    set((state) => ({ filter: { ...state.filter, indexPrice: price } })),
  updateSort: (sort) => set((state) => ({ filter: { ...state.filter, sort } })),
  updatePage: (page) => set((state) => ({ filter: { ...state.filter, page } })),
  updateTotal: (total) =>
    set((state) => ({ filter: { ...state.filter, total } })),
  resetFilter: (style?: number) => {
    if (style) {
      set(() => ({
        filter: { ...FilterStateInitial.filter, styles: [style] },
      }));
    } else {
      set(() => ({ filter: { ...FilterStateInitial.filter } }));
    }
  },
  getResultCount: () => get().filter.total,
  setCategory: (category) =>
    set((state) => ({ filter: { ...state.filter, category } })),
  setInitialStyle: (style) =>
    set((state) => ({ filter: { ...state.filter, styles: [style] } })),
  updateStyle: (style) =>
    set((state) => ({
      filter: {
        ...state.filter,
        styles: state.filter.styles.includes(style)
          ? state.filter.styles.filter((item) => item !== style)
          : [...state.filter.styles, style],
      },
    })),
  setOrigin: (origin) =>
    set((state) => {
      const currentOrigin = state.filter.origin ?? []; // Ensure it's always an array
      return {
        filter: {
          ...state.filter,
          origin: currentOrigin.includes(origin)
            ? currentOrigin.filter((item) => item !== origin) // Remove if it already exists
            : [...currentOrigin, origin], // Add otherwise
        },
      };
    }),
  removeOrigin: (origin) =>
    set((state) => ({
      filter: {
        ...state.filter,
        origin: state.filter.origin.filter((item) => item !== origin),
      },
    })),
});

export const useFilterStore = create<IFilterState>()(
  persist(
    (...a) => ({
      ...createFilterStoreSlice(...a),
    }),
    {
      name: 'jwl-settings',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
