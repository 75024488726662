import { IItem } from "@contexts/store/store-types";
import { TProduct } from "@services/mage/catalog-product-types";
import { ProductUtils } from "@services/mage/catalog-product-utils";
import { TDiamond } from "@services/stone/types";
import { tagEvent } from "@utils/gtag";

export class GoogleEvents {

    // view item
    static async addToCart(currency: string, items: IItem[]) {

        // cart items
        const {cartItems, rowTotal} = this.cartItems(items);
        
        // google tag manager
        tagEvent('add_to_cart', {
            currency: currency,
            value: rowTotal,
            items: cartItems
        })

    }

        // view cart
        static async viewToCart(currency: string, items: IItem[]) {

            // cart items
            const {cartItems, rowTotal} = this.cartItems(items);            
                
            // google tag manager
            tagEvent('view_cart', {
                currency: currency,
                value: rowTotal,
                items: cartItems
            })
        
        }

    static async viewItem(product: TProduct | TDiamond, currency: string) {

        // Jewelry
        if ("name" in product) {
            tagEvent('view_item', {
                    currency: currency,
                    value: product.price,
                    items: [
                        {
                        item_id: product.id,
                        item_name: product,
                        affiliation: "Michael Trio",
                        coupon: product?.sale_option?.discount_lbl,
                        discount: product?.sale_option?.discount_amt,
                        index: 0,
                        item_brand: "Michael Trio",                  
                        location_id: `${product.sku}_${product.id}`,
                        price: product.price,
                        quantity: 1
                        }
                    ]
                });
        }

        // Diamond product
        if ("stone_prefix" in product) {
            tagEvent('view_item', {
                    currency: currency,
                    value: product.cost,
                    items: [
                        {
                        item_id: product.id,
                        item_name: product.sku,
                        affiliation: "Michael Trio",
                        coupon: product?.sale_option?.discount_lbl,
                        discount: product?.sale_option?.discount_amt,
                        index: 0,
                        item_brand: "Michael Trio",                  
                        location_id: `${product.sku}_${product.id}`,
                        price: product.cost,
                        quantity: 1
                        }
                    ]            
            });
        }

    }

    /**
     * Add to wishlist
     * @param currency 
     * @param items 
     */
    static async addToWishlist(currency: string, items: IItem[]) {

        // cart items
        const {cartItems, rowTotal} = this.cartItems(items);
            
        // google tag manager
        tagEvent('add_to_wishlist', {
            currency: currency,
            value: rowTotal,
            items: cartItems
        })

    }

    /**
     * Begin checkout
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#begin_checkout
     * @param currency 
     * @param coupon 
     * @param items 
     * @returns
     */
    static async beginCheckout(currency: string, coupon: string, items: IItem[]) {

        // cart items
        const {cartItems, rowTotal} = this.cartItems(items);
        
        // google tag manager
        tagEvent('begin_checkout', {
            currency: currency,
            value: rowTotal,
            coupon: coupon,
            items: cartItems
        })

    }

    /**
     * Add shipping info
     * @param currency 
     * @param coupon 
     * @param items 
     */
    static async addShippingInfo(currency: string, coupon: string, items: IItem[]) {

        // cart items
        const {cartItems, rowTotal} = this.cartItems(items);
        
        // google tag manager
        tagEvent('add_shipping_info', {
            currency: currency,
            value: rowTotal,
            coupon: coupon,
            shipping_tier: "standard",
            items: cartItems
        })

    }    

    /**
     * Add payment info
     * @param currency 
     * @param coupon 
     * @param items 
     */
    static async addPaymentInfo(currency: string, coupon: string, paymentType: string, items: IItem[]) {

        // cart items
        const {cartItems, rowTotal} = this.cartItems(items);
        
        // google tag manager
        tagEvent('add_payment_info', {
            currency: currency,
            value: rowTotal,
            coupon: coupon,
            payment_type: paymentType,
            items: cartItems
        })
    
    }    

    static cartItems(items: IItem[]) {
        // cart items
        let cartItems = []
    
        let idx = 0;
        let rowTotal = 0;

        items.forEach((item: IItem) => {
    
            const itemCategoryNames = ProductUtils.getCategoryNames(item.categoryIds);
            
            cartItems.push({
                item_id: item.id,
                item_name: item.name,
                affiliation: "Michael Trio",
                coupon: item?.sale_option?.discount_lbl,
                discount: item?.sale_option?.discount_amt,
                index: idx,
                item_brand: "Michael Trio",
                item_category: itemCategoryNames[0],                  
                location_id: `${item.sku}_${item.id}`,
                price: item.price,
                quantity: item.quantity
            })

            rowTotal += item.price;
    
            idx++;
        });

        return {cartItems: cartItems, rowTotal: rowTotal};
    }
    

}